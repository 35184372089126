<script lang="ts">
import type { Project } from '~/types/project'

export const PROJECT_HEADER_INFOCARD_CLASS = 'project-header-infocard'
</script>

<script lang="ts" setup>
const props = defineProps<{
  project: Project
}>()

defineSlots<{
  description: void
}>()

const projectInfo = {
  'Auftraggeber': props.project.header.info_card.client,
  'Unsere Rolle': props.project.header.info_card.roles,
  'Projektzeitraum': props.project.header.info_card.project_duration,
}

useAnime({
  targets: `.${PROJECT_HEADER_INFOCARD_CLASS}`,
  translateY: ['200%', 0],
  easing: 'spring(1, 80, 12, 0)',
})
</script>

<template>
  <Tilt
    contain
    class="bg-primary px-8 font-semibold motion-safe:invisible max-md:py-tilt md:px-12 md:[--top-left-y-factor:0]"
    :class="[
      PROJECT_HEADER_INFOCARD_CLASS,
      project.header.info_card.font_black ? 'text-black' : 'text-white',
    ]"
  >
    <p class="mb-8 mt-12 text-xl font-semibold italic lg:pr-14">
      <slot name="description" />
    </p>
    <dl class="flex flex-col lg:pr-14">
      <template v-for="[title, info] of Object.entries(projectInfo)" :key="title">
        <dt class="text-xl font-extrabold italic text-secondary md:text-3xl">
          {{ title }}
        </dt>
        <dd class="mb-6 mt-4 flex flex-wrap gap-2 font-mono max-md:text-sm">
          <template v-if="Array.isArray(info)">
            <KeywordTag v-for="text of info" :key="text" :text="text" />
          </template>
          <template v-else>
            {{ info }}
          </template>
        </dd>
      </template>
    </dl>
    <div class="my-12 flex justify-end">
      <div class="relative text-white">
        <Diagonals
          class="absolute right-0 top-0 origin-right rotate-[130deg] md:hidden lg:block"
          :class="[project.header.info_card.custom_button_class || 'bg-secondary']"
        />
        <ElbworxLink
          :class="[
            project.header.info_card.project_url || 'invisible h-0 !p-0',
            project.header.info_card.custom_button_class || 'bg-secondary',
          ]"
          :href="project.header.info_card.project_url"
          class="group relative block overflow-hidden px-2 pb-1 pt-2 font-mono text-lg transition-colors duration-300 hover:text-black"
          external
          target="_blank"
        >
          <!-- TODO: use <HoverFillButton /> -->
          <div
            class="absolute left-1/2 top-1/2 w-[110%] -translate-x-1/2 -translate-y-1/2 scale-0 rounded-full bg-elbworx-yellow pt-[110%] transition-transform duration-300 ease-in-out group-hover:scale-100"
          />
          <span class="relative">Zur Website</span>
        </ElbworxLink>
      </div>
    </div>
  </Tilt>
</template>
